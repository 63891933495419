import React from 'react'

import '../styles/infographic.css'

function Infographic(props) {
    const { backgroundColor, paddingTop, paddingBottom, image, title, description, maxWidth, imageStyle, titleStyle, children } = props;
    return (
        <div className="infographic-container" style={{ backgroundColor, paddingTop, paddingBottom }}>
            <div className="infographic-image animated fadeIn" style={Object.assign({ backgroundImage: `url('${image}')` }, imageStyle)} />
            <div className="cta-title animated fadeIn" style={titleStyle}>{title}</div>
            <div className="infographic-description animated fadeIn" style={{ maxWidth }}>{description}</div>
            { children }
        </div>
    )
}

export default Infographic;

