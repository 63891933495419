import React from 'react'
import Layout from "../../components/Layout";
import Showcase from "../../components/Showcase";

import Cta from "../../components/Cta";
import ServicesBg from "../../images/services.png"

import Infographic from "../../components/Infographic";

import WorksEverywhere from "../../images/works-everywhere.png"
import Kilogram from "../../images/kilogram.png"
import HollowKnight from "../../images/hollow-knight-ipad.png"
import SuperseedStudios from "../../images/superseed-studios-laptop.png"
import OverwatchLeague from "../../images/overwatchleague.png"
import PlayOverwatch from "../../images/playoverwatch.png"
// import SDLC from "../../images/SDLC.png"
import River from "../../images/river.png"
import BnetShop from "../../images/bnet-shop.png"
import HFP from "../../images/hfp.png"

function Portfolio() {
  return (
    <Layout noNav={true}>

      <div id="custom-spacer" style={{ paddingTop: 80 }}/>

      <Infographic 
        paddingTop="5em"
        paddingBottom="6em"
        image={WorksEverywhere} 
        title="work that works everywhere." 
        titleStyle={{ paddingTop: '0' }}
        description="I've designed, engineered and project managed websites, web apps, and mobile apps. Here are some samples out of my personal and professional profolios." 
        maxWidth={'24em'}
      >
      </Infographic>

      <hr className="divider" />

      <Showcase 
        reverse={true}
        type={'laptop'}
        color={''}
        textColor={'dark'}
        title={'Overwatch'}
        link={'https://playoverwatch.com'}
        image={PlayOverwatch}
        tags={['gaming', 'multinational', 'website', 'engineering', 'scaled']}
        description={[
          'As a full time Software Engineer at Blizzard Entertainment I built the front end/user facing portions of the official website for the award winning game Overwatch.',
          'It was done securely on a short timeline in a high expectation environment. Any leaks would have been disastrous to the company and we had to scale it for the launch of the game, which meant potentially millions of hits per minute. The team delivered on all fronts and we launched a beautiful website.'
        ]}
      />
      <Showcase 
        reverse={false}
        type={'tablet'}
        color={''}
        textColor={'dark'}
        title={'Hollow Knight'}
        image={HollowKnight}
        link={'https://hungry-kilby-bf32ff.netlify.com/'}
        tags={['gaming', 'website', 'design', 'engineering', 'project management']}
        caseStudylink={'/case-studies/hollow-knight'}
        description={[
          'Hollow Knight is a successful independently developed video game for multiple gaming platforms including Playstation 4, Xbox One, Nintendo Switch, PC, and more. I rebuilt and redesigned their website with the goal of improving sales conversion.', 
          'I worked with a quick deadline: 2 weeks to redesign and rebuild their website on a modern technology stack, improve usability and sales conversion, and allow for non-developer to edit site content. I delivered a beautiful new website design and launched it onto a testing environment in just 10 days.'
        ]}
      />
      <Showcase
        reverse={false}
        type={'laptop'}
        color={''}
        textColor={'dark'}
        title={'Overwatch League'}
        link={'https://overwatchleague.com'}
        image={OverwatchLeague}
        tags={['gaming', 'multinational', 'website', 'engineering', 'api', 'scaled']}
        description={[
          'As a full time Software Engineer with Blizzard Entertainment, I worked with a team tasked with building an online destination for a professional league that could rival the NBA.',
          'I worked extensively on the front end of the website as well as the api platform that supported the cooresponding iOS application. It held up to phenomenal traffic from millions of passionate users across twelve different languages and over twenty countries.'
        ]}
      />
      <Showcase 
        reverse={true}
        type={'laptop'}
        color={''}
        textColor={'dark'}
        title={'Superseed Studios'}
        link={'https://superseedstudios.com'}
        image={SuperseedStudios}
        tags={['animation', 'website', 'design', 'engineering', 'project management']}
        description={[
          'Superseed Studios is a premier remote animation studio that serves large video game companies and media corporations in providing gameplay and cinematic animations.',
          'I worked on a complete brand design for the studio to create a consistent brand across their company and built a website for the studio that attracts large clients by writing content that clearly communicates their services and showcases highlights of their past work.'
        ]}
      />
      <Showcase 
        reverse={false}
        type={'phone'}
        color={''}
        textColor={'dark'}
        title={'Kilogram'}
        image={Kilogram}
        tags={['social', 'photography', 'mobile', 'design', 'engineering', 'project management']}
        description={[
          'Our client wanted to create a photography microblogging social platform. They needed help designing a mobile application that properly represented their vision and would appeal to a young creative audience.',
          'We helped them solidify their ideas into a sleek and modern iOS application and extensively user tested the prototypes amongst the targeted 18-35 audience in order to guarantee a stellar user experience. We delivered a design that our client was ecstatic to receive.'
        ]}
      />
      <Showcase 
        reverse={true}
        type={'phone'}
        color={''}
        textColor={'dark'}
        title={'River'}
        image={River}
        tags={['music', 'social', 'mobile', 'design', 'engineering']}
        description={[
          'A collaborative playlist application built on top of the Spotify API. Our charter was to design and create a more communal way of listening to music, as well as creating and sharing playlists.',
          'We designed this clean and feature rich iOS application that our client was thrilled by. It was praised as beautiful, modern, easy to use, and strongly appealed to our client’s core audience demographic.'
        ]}
      />
      <Showcase 
        reverse={false}
        type={'laptop'}
        color={''}
        textColor={'dark'}
        title={'Holistic Financial'}
        link={'https://www.holistic-financial-planning.com/'}
        image={HFP}
        tags={['finance', 'website', 'design', 'engineering', 'project management']}
        description={[
          "We redesigned the website and branding for a financial planning firm in Irvine California with the goal of improving lead generation and clearly presenting company messaging.",
          'We also helped them cut down on their web hosting costs by 300% by getting rid of an unnecessary subscription CMS and freed up their staff to focus on their core business instead of trying to juggle and maintain a DIY website.'
        ]}
      />

    <hr className="divider" />

    <div className="cta-container">
      <div className="cta-content">
        <div className="cta-title">let's see what I can help you with</div>
        <a href="mailto:me@erict.dev">
          <div className="cta-button">email me</div>
        </a>
      </div>
      <div className="cta-image" style={{ backgroundImage: `url(${ServicesBg})`}} />
    </div>

  </Layout>


  )
}

export default Portfolio;
